/* eslint-disable @typescript-eslint/no-explicit-any */
import { get, orderBy } from 'lodash'
import { RoadTruck, SeaContainer } from './tabs/transportation/types'
import { CMRDocument } from './tabs/transportation/cmr/cmrDocuments'

export interface Dimensions {
  width: number
  height: number
  length: number
  unit: 'mm' | 'cm' | 'm' | 'in' | 'ft'
  quantity?: number
  volume?: {
    value: number
    unit: 'mm3' | 'cm3' | 'm3' | 'in3' | 'ft3'
  }
}

export interface Colli {
  id?: string
  quantity: number
  netWeight?: { value: number; unit: string }
  grossWeight?: { value: number; unit: string }
  dimensions: Dimensions
}
function isMetric(unit: string): boolean {
  return ['mm', 'cm', 'm'].includes(unit)
}

function isImperial(unit: string): boolean {
  return ['in', 'ft'].includes(unit)
}

function formatNumber(num: number): string {
  // If the number is smaller than 0.001, show the full number with up to 10 decimal places
  if (num > 0 && num < 0.001) {
    return Number(num).toFixed(10)
  } else {
    // Otherwise, round the number to 3 decimal places
    return Number(num.toFixed(3)).toString()
  }
}

export function calculateVolumeWithUnit(colli: Colli): string {
  const {
    width = 0,
    height = 0,
    length = 0,
    unit,
  } = colli?.dimensions || { width: 0, height: 0, length: 0 }

  // Calculate the volume
  const volume = calculateVolume({
    length,
    width,
    height,
    unit,
    quantity: colli.quantity || 1,
  })

  // Determine the unit of volume based on the input unit
  let volumeUnit = ''
  if (unit === 'mm' || unit === 'cm' || unit === 'm') {
    volumeUnit = 'm³'
  } else if (unit === 'in' || unit === 'ft') {
    volumeUnit = 'ft³'
  }

  // Format the output to include the volume and its unit
  return `${formatNumber(volume)} ${volumeUnit}`
}

export function calculateTotalRowsVolume(collis: Colli[]): string {
  if (collis.length === 0) return ''

  const firstUnit = collis[0].dimensions?.unit
  const isAllMetric = collis.every((colli) => isMetric(colli.dimensions?.unit))
  const isAllImperial = collis.every((colli) =>
    isImperial(colli.dimensions?.unit),
  )

  if (!isAllMetric && !isAllImperial) {
    return '' // Mixed measurement systems, so return an empty string
  }

  // Sum the volume of all rows
  const totalVolume = collis.reduce((acc, colli) => {
    const volume = calculateVolumeValue({
      volumeValue: colli.dimensions.volume?.value || 0,
      unit: colli.dimensions?.unit,
    })
    return acc + volume
  }, 0)

  // Determine the unit of volume for the total
  const volumeUnit = isMetric(firstUnit) ? 'm³' : 'ft³'

  // Format the output
  return `${formatNumber(totalVolume)} ${volumeUnit}`
}

export function calculateTotalRowsVolumem3(collis: Colli[]): any {
  if (!collis || collis?.length === 0)
    return { totalVolume: 0, volumeUnit: null }
  const firstUnit = collis[0].dimensions?.unit
  const isAllMetric = collis.every((colli) => isMetric(colli.dimensions?.unit))
  const isAllImperial = collis.every((colli) =>
    isImperial(colli.dimensions?.unit),
  )

  if (!isAllMetric && !isAllImperial) {
    return { totalVolume: 0, volumeUnit: 'mixed units' } // Mixed measurement systems, so return an empty string
  }

  // Sum the volume of all rows
  const totalVolume = collis.reduce((acc, colli) => {
    return (
      acc +
      convertVolumetoM3(
        colli.dimensions?.volume?.value || 0,
        colli.dimensions?.volume?.unit || 'm3',
      )
    )
  }, 0)

  // Determine the unit of volume for the total
  const volumeUnit = isMetric(firstUnit) ? 'm³' : 'ft³'

  // Format the output
  return { totalVolume, volumeUnit }
}

export function calculateVolume({
  length,
  width,
  height,
  unit,
  quantity = 1,
}: Dimensions): number {
  if (unit === 'mm') {
    length /= 1000
    width /= 1000
    height /= 1000
  } else if (unit === 'cm') {
    length /= 100
    width /= 100
    height /= 100
  } else if (unit === 'in') {
    length /= 12
    width /= 12
    height /= 12
  }

  const volume = length * width * height * quantity

  return Number(volume)
}

export const calculateVolumeM3 = ({
  length,
  width,
  height,
  unit,
  quantity = 1,
}: Dimensions) =>
  calculateVolume({
    length,
    width,
    height,
    unit,
    quantity,
  })

export function calculateVolumeValue({ volumeValue, unit }: any): number {
  if (unit === 'mm') {
    return volumeValue / 1000000000
  } else if (unit === 'cm') {
    return volumeValue / 1000000
  } else if (unit === 'in') {
    return (volumeValue / 12) * 12 * 12
  }

  return Number(volumeValue)
}

export const getVolumeUnit = (unit: 'mm' | 'cm' | 'm' | 'in' | 'ft') => {
  switch (unit) {
    case 'mm':
    case 'cm':
    case 'm':
      return 'm3'
    case 'in':
    case 'ft':
      return 'ft3'
    default:
      return 'm3'
  }
}
export function convertVolumetoM3(
  volume: number,
  unit: 'mm3' | 'cm3' | 'm3' | 'mm' | 'cm' | 'm' | 'in3' | 'ft3',
): number {
  let totalVolume: number

  switch (unit) {
    case 'mm3':
    case 'mm':
      totalVolume = volume / 1e9 // 1 mm³ = 1e-9 m³
      break
    case 'cm3':
    case 'cm':
      totalVolume = volume / 1e6 // 1 cm³ = 1e-6 m³
      break
    case 'm3':
    case 'm':
      totalVolume = volume // 1 m³ = 1 m³
      break
    case 'in3':
      totalVolume = volume * 0.0000163871 // 1 in³ = 1.63871e-5 m³
      break
    case 'ft3':
      totalVolume = volume * 0.0283168 // 1 ft³ = 0.0283168 m³
      break
    default:
      throw new Error('Unsupported unit')
  }
  return Number(totalVolume)
}

export interface colliInfo {
  colliTotal: number
  commodityColliSum: number
  isValid: boolean
}

export function calculateColliQuantitiesSeaAndRoadContainer(
  colliId: string,
  contianers: SeaContainer[] | RoadTruck[],
  comodityCollis: Colli[],
  indx: number,
  type: 'sea' | 'road',
): colliInfo {
  // Find the quantity of the colli in comodityCollis
  const commodityColli = comodityCollis.find((colli) => colli.id === colliId)
  const commodityColliSum = commodityColli ? commodityColli.quantity : 0
  let currentContainerId = ''

  if (type === 'sea')
    currentContainerId = (contianers?.[indx] as SeaContainer)?.containerId || ''
  else currentContainerId = (contianers?.[indx] as RoadTruck)?.id || ''

  let contianersColliTotal = 0
  contianers.forEach((contianer: SeaContainer | RoadTruck) => {
    contianer.collis?.forEach((colli: any) => {
      if (indx === -1 && colli?.id === colliId) {
        contianersColliTotal += colli.quantity
      } else {
        if (
          colli?.id === colliId &&
          currentContainerId !==
            ((contianer as any)?.containerId ?? (contianer as any)?.id)
        ) {
          contianersColliTotal += colli.quantity
        }
      }
    })
  })
  const isValid = contianersColliTotal <= commodityColliSum

  return { colliTotal: contianersColliTotal, commodityColliSum, isValid }
}

export function calculateQuantities(
  colliId: string,
  transportationContainers: any,
  commodity: any,
  containerFormik: any,
  indx: number,
) {
  const { commodityColliSum, colliTotal } =
    calculateColliQuantitiesSeaAndRoadContainer(
      colliId,
      transportationContainers || [],
      commodity.collis || [],
      containerFormik.values?.collis[indx].id,
      'sea',
    )

  const thisContainerQty = containerFormik.values?.collis
    ? containerFormik.values?.collis[indx].quantity
    : 0

  const hasInvalidQty = containerFormik.values?.collis
    ? thisContainerQty + colliTotal > commodityColliSum
    : false

  const remaining = commodityColliSum - colliTotal - thisContainerQty

  return {
    commodityColliSum,
    colliTotal,
    thisContainerQty,
    hasInvalidQty,
    remaining,
  }
}

export function sumGrossWeightAndVolume(items: any) {
  if (!items || items?.length === 0) {
    return { grossWeight: 0, volume: 0 }
  }

  const result = items?.reduce(
    (acc: any, item: any) => {
      acc.grossWeight += item.grossWeight.value
      acc.volume += item.dimensions.volume.value
      return acc
    },
    { grossWeight: 0, volume: 0 },
  )

  return result
}
export const getColliVolume = ({ containerColli, shipmentColli, key }: any) => {
  if (containerColli.quantity === 0) return 0

  return (
    ((get(shipmentColli, key) || 0) * containerColli.quantity) /
    get(shipmentColli, 'quantity')
  )
}
export function calculateLoadingMeters(collis: Colli[]): number {
  const truckWidth = 2.4 // width of the truck in meters
  let totalLDM = 0

  collis.forEach((item) => {
    const { length, width } = item?.dimensions || {}
    const areaPerColli = length * width
    const loadingMeterPerColli = areaPerColli / truckWidth
    totalLDM += loadingMeterPerColli * item.quantity
  })

  return totalLDM
}

export const getVolumeinCM = (
  value?: number,
  unit?: 'mm' | 'cm' | 'm' | 'in' | 'ft',
): number => {
  if (!value) return 0 // Return 0 if no value is provided

  switch (unit) {
    case 'mm':
      return value / 1000 // 1 mm3 = 0.001 cm3
    case 'cm':
      return value // 1 cm3 = 1 cm3
    case 'm':
      return value * 1e6 // 1 m3 = 1,000,000 cm3
    case 'in':
      return value * 16.3871 // 1 in3 = 16.3871 cm3
    case 'ft':
      return value * 28316.8 // 1 ft3 = 28,316.8 cm3
    default:
      return value // If unit is not provided or unrecognized, assume it's in cm3
  }
}

export const getVolumeinM3 = (
  value?: number,
  unit?: 'mm' | 'cm' | 'm' | 'in' | 'ft',
): number => {
  if (!value) return 0 // Return 0 if no value is provided

  switch (unit) {
    case 'mm':
      return value / 1e9 // 1 mm³ = 1e-9 m³
    case 'cm':
      return value / 1e6 // 1 cm³ = 1e-6 m³
    case 'm':
      return value // 1 m³ = 1 m³
    case 'in':
      return value * 0.0000163871 // 1 in³ = 1.63871e-5 m³
    case 'ft':
      return value * 0.0283168 // 1 ft³ = 0.0283168 m³
    default:
      return value / 1e6 // If unit is not provided or unrecognized, assume it's in cm³
  }
}

export function calculateChargableWeight(collis: Colli[]): string {
  const densityRatio = 6000
  let totalChargableWeight = 0

  collis.forEach((item) => {
    const { length, width, height, unit } = item.dimensions || {}
    const volume = length * width * height
    const volumeinCm = getVolumeinCM(volume, unit || 'cm3')
    const loadingMeterPerColli = volumeinCm / densityRatio
    totalChargableWeight += loadingMeterPerColli * item.quantity
  })

  return (totalChargableWeight || 0).toFixed(3)
}

export function toSentenceCase(str: string): string {
  return str
    ?.toLowerCase() // Convert the entire string to lowercase first
    .replace(/(^\s*\w|[\\.\\?\\!]\s*\w)/g, function (c) {
      return c.toUpperCase() // Capitalize the first letter of each sentence
    })
}

export const findLatestCMRPath = (list: any, contianerID?: string) => {
  const filteredCmrs = contianerID
    ? list.filter((x: CMRDocument) => x.containerId === contianerID)
    : list

  const ordered = orderBy(filteredCmrs, ['createdAt'], ['desc'])
  if (ordered.length > 0) {
    return ordered[0].path
  }
}

export const getDefaultStatus = (mot: string | undefined): string | null => {
  switch (mot) {
    case 'sea':
      return null
    case 'air':
      return null
    default:
      return null
  }
}

export const getDefaultShippingType = (
  mot: string | undefined,
): string | null => {
  switch (mot) {
    case 'sea':
      return null
    case 'ferry':
    case 'road':
      return null
    default:
      return null
  }
}
