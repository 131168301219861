/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import PlusIcon from '@mui/icons-material/Add'
import {
  Box,
  CircularProgress,
  MenuItem,
  Popper,
  TextField,
} from '@mui/material'
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'
import { useTheme } from '@mui/material/styles'
import { uniqBy } from 'lodash'
import debounce from 'lodash/debounce'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useGetAutocompleeteData } from 'src/common/apis'
import { useModal } from 'src/common/hooks/use-modal'
import { Option } from '../../grid/types'
import { AutocompleteWithCreateProps } from './types'

const CREATE_NEW_OPTION = 'create-new-option'

const DynamicAutocomplete = ({
  value,
  name = '',
  setFieldValue,
  index,
  label,
  readOnly = false,
  allowCreate = true,
  size,
  ModalComponent,
  modalProps,
  multiple = false,
  placeholder,
  getLabel,
  filter,
  onModalSubmit,
  error,
  touched,
  handleBlur,
  setFieldTouched,
  options: optionsProp,
  afterFilter,
  onChange,
  variant,
  isLoading: isLoadingRequest,
  getInputValue,
  ...otherProps
}: AutocompleteWithCreateProps) => {
  const [createdItem, setcreatedItem] = useState(null)
  const [termValue, setTerm] = useState('')
  const [isUserTyping, setIsUserTyping] = useState(false)
  const [selectionChanged, setSelectionChanged] = useState(false)
  const [isDownOrUpKeyPressed, setisDownOrUpKeyPressed] = useState(false)
  const inputRef = useRef<HTMLInputElement | null>(null)
  const { open, handleOpen, handleClose: handleModalClose } = useModal()
  const theme = useTheme()
  const containerRef = useRef<HTMLDivElement | null>(null)

  const debouncedSetInputValue = debounce((changedValue: string) => {
    getInputValue && getInputValue(changedValue)
    if (isUserTyping) {
      setTerm(changedValue)
    }
  }, 500)

  const removeCreateNewOption = (item: Option) =>
    item?.value !== CREATE_NEW_OPTION

  const { isLoading, data } = useGetAutocompleeteData({
    index,
    term: termValue,
    getLabel,
    filter,
    afterFilter,
  })
  const options = useMemo(() => {
    if (optionsProp) {
      return optionsProp
    }
    return data || []
  }, [data, optionsProp])

  const noResultFound =
    options.length === 0 && termValue?.length > 0
      ? ' No result matched with your search '
      : ''

  const valuesArray = useMemo(
    () => {
      return Array.isArray(value) ? value : value ? [value] : []
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(value)],
  )

  const optionsIncludingValue = useMemo(() => {
    return value ? uniqBy([...valuesArray, ...options], 'value') : options
  }, [valuesArray, options, value])

  const allOptions = useMemo(() => {
    if (allowCreate) {
      return [
        {
          value: CREATE_NEW_OPTION,
          label: 'Add new  ',
          id: 'autocomplete-new',
        },
        ...optionsIncludingValue,
      ]
    }
    return optionsIncludingValue
  }, [allowCreate, isLoading, optionsIncludingValue])

  const submitCallback = async (newSavedItem: any) => {
    setcreatedItem(newSavedItem)
  }

  const handleOpenCreateNewItem = () => {
    handleOpen()
    inputRef.current && inputRef.current.blur()
  }

  const filterOptionsFn = createFilterOptions({
    stringify: (option: Option) => {
      return typeof option?.label === 'string'
        ? option?.label
        : option?.value?.toString()
    },
    matchFrom: 'any',
    limit: Infinity,
  })

  const showNoOptions =
    options.length === 0 ||
    (options.length === 1 && options[0].value === CREATE_NEW_OPTION)

  // Effect for handling when a user stops typing
  useEffect(() => {
    if (isUserTyping && !selectionChanged && !multiple) {
      setFieldValue(name, null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [multiple, selectionChanged])

  // Separate effect for resetting selection change
  useEffect(() => {
    // Reset the flag after effects run if needed
    if (selectionChanged) {
      setSelectionChanged(false)
    }
  }, [selectionChanged])

  const handleAutocomleteChange = (
    _: any,
    newValue: Option[] | Option | null,
  ) => {
    setSelectionChanged(true)
    // setFieldTouched(name)
    setIsUserTyping(false)

    const validItems = multiple
      ? (newValue as Option[])?.filter(removeCreateNewOption)
      : newValue
      ? [newValue as Option]?.filter(removeCreateNewOption)
      : []

    setFieldValue(name, multiple ? validItems : validItems[0] || null)
    onChange && onChange(multiple ? validItems : validItems[0] || null)
    setisDownOrUpKeyPressed(false)
  }
  const handleAutocomleteChangeNewItem = async (
    _: any,
    newValue: Option | null,
  ) => {
    setSelectionChanged(true)
    await setFieldTouched(name)
    setIsUserTyping(false)

    newValue &&
      (await setFieldValue(name, multiple ? [newValue] : newValue || null))
  }

  useEffect(() => {
    if (createdItem) {
      const newOption = onModalSubmit ? onModalSubmit(createdItem) : createdItem

      createdItem && handleAutocomleteChangeNewItem(null, newOption)
      setcreatedItem(null)
    }
  }, [createdItem, onModalSubmit])

  const renderOption = (props: any, option: Option, { selected }: any) => {
    if (
      !option?.label &&
      (option?.id === null || option?.id === undefined || option?.id === '')
    ) {
      return null
    }

    return option.value === CREATE_NEW_OPTION ? (
      <MenuItem
        {...props}
        onClick={handleOpenCreateNewItem}
        key="create-new-option"
        id="create-new-option"
        disabled={readOnly}
        sx={{ width: '100%' }}
      >
        <PlusIcon /> Add new{' '}
        {noResultFound && (
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'end',
              alignContent: 'center',
            }}
          >
            <small>{noResultFound}</small>
          </Box>
        )}
      </MenuItem>
    ) : (
      <MenuItem {...props} selected={selected} key={option.id} id={option.id}>
        {option.label}
      </MenuItem>
    )
  }
  const isOptionEqualToValue = (option: Option, selectedOption: any) => {
    let isEqual = false

    if (!multiple && Array.isArray(selectedOption)) {
      isEqual = option?.value === selectedOption[0]?.value
    } else {
      isEqual = option?.value === selectedOption?.value
    }

    return isEqual
  }

  return (
    <div ref={containerRef}>
      <Autocomplete
        id={name + '-autocomplete'}
        PopperComponent={(props: any) => (
          <div id={'autocomplete-container-' + name}>
            <Popper
              {...props}
              container={containerRef.current}
              sx={{ zIndex: 999999999 }}
              onMouseEnter={() => setisDownOrUpKeyPressed(false)}
            />
          </div>
        )}
        noOptionsText={showNoOptions ? 'No options available' : null}
        placeholder={placeholder}
        readOnly={readOnly}
        options={allOptions}
        getOptionLabel={(option: Option) => {
          return (option?.label as string) || ''
        }}
        isOptionEqualToValue={isOptionEqualToValue}
        autoSelect={isDownOrUpKeyPressed}
        autoHighlight
        multiple={multiple}
        value={multiple ? valuesArray : valuesArray[0] || null}
        onInputChange={(event, newInputValue, reason) => {
          if (reason === 'input') {
            setIsUserTyping(true)
            debouncedSetInputValue(newInputValue)
          }
        }}
        onChange={handleAutocomleteChange}
        renderOption={renderOption}
        onBlur={(e) => {
          handleBlur(e)
          setFieldTouched(name)
          setisDownOrUpKeyPressed(false)
        }}
        onKeyDown={(e) => {
          if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
            setisDownOrUpKeyPressed(true)
          }

          if (e.key === 'Tab') {
            // Filter out the object with id "autocomplete-new"
            const filteredOptions = allOptions.filter(
              (option: { id: string }) => option.id !== 'autocomplete-new',
            )

            // Ensure there is only one element left
            if (filteredOptions.length === 1) {
              multiple
                ? setFieldValue(name, filteredOptions)
                : setFieldValue(name, filteredOptions[0])
              onChange && onChange(filteredOptions[0])
            }
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            id={name + '-autocomplete-input'}
            fullWidth
            label={label}
            name={name}
            helperText={!!touched && !!error ? error || '' : ''}
            error={!!touched && !!error}
            placeholder={
              isLoading || isLoadingRequest ? 'Loading data...' : placeholder
            }
            onBlur={(e) => {
              handleBlur(e)
            }}
            inputRef={inputRef}
            size={size}
            variant={variant}
            InputProps={{
              ...params.InputProps,
              readOnly: readOnly,
              endAdornment: (
                <div>
                  {isLoading ? (
                    <CircularProgress
                      size={18}
                      style={{
                        position: 'absolute',
                        right: 10,
                        top: 20,
                        color: theme.palette.grey[500],
                      }}
                    />
                  ) : (
                    params.InputProps.endAdornment
                  )}
                </div>
              ),
            }}
          />
        )}
        filterOptions={(options, params) => {
          try {
            const filtered = filterOptionsFn(options, params)
            if (params.inputValue !== '') {
              const existsNew =
                filtered.findIndex(
                  (item: Option) => item.value === CREATE_NEW_OPTION,
                ) !== -1

              if (!existsNew && allowCreate) {
                filtered.unshift({ value: CREATE_NEW_OPTION, label: 'Add new' })
              }
            }
            return filtered
          } catch (e) {
            console.log({ e, params })
            return options
          }
        }}
        {...otherProps}
      />
      {open && ModalComponent && (
        <ModalComponent
          open={open}
          handleClose={handleModalClose}
          submitCallback={submitCallback}
          multiple={multiple}
          {...modalProps}
        />
      )}
    </div>
  )
}

export default DynamicAutocomplete
